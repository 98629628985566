<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@/components";
import ArticleFrame from "./components/ArticleFrame.vue";

export default {
  name: "HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite",
  components: {
    SuawMainContent,
    SuawParagraph,
    // eslint-disable-next-line vue/no-unused-components
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite">
      <SuawParagraph
        text="It took me two and a half years to write and edit my first nonfiction novel, and I couldn’t have completed it without the encouragement of Shut Up &amp; Write!’s NYC chapter."
      />
      <SuawParagraph
        text="It’s difficult to motivate yourself to work on your craft. Time is limited and precious. Luckily for me, the Shut Up &amp; Write! events in Upper Manhattan on Wednesday and Sunday nights were just the right amount of weekly motivation I needed. I was already determined to finish my novel, devoting my weekends to writing, but weekdays were near impossible for me to write. You see, I work from home as a full-time artist. In the evenings, I just wanted to watch a film or decompress from the day’s hustle. But I soon realized that it was more important to get a healthy dose of human contact mid-week and attend a writing event instead. And on Sunday, after writing at least five hours per day all weekend, it was again nice to touch base with friendly writers-to-be and enjoy the energy created by the group."
      />
      <SuawParagraph
        text="The hosts, either Shannon or Ed, would always be there before anyone arrived. They were (and still are) always friendly. Introductions were simple: You would state your name and what you were working on. Then you’d shut up and write! The Wednesday session was usually held at a public space named the <a href='https://www.lincolncenter.org/venue/david-rubenstein-atrium' target='_blank'>Rubenstein Atrium</a> which had a little cafe in the corner, but you were not obligated to buy anything; you could just join in the writing. The Sunday session was held at Argo Cafe and I’d usually purchase something to support the establishment. I have a sweet tooth for their apricot danish — a little energy boost. It was easy to chat with attendees before or after the sessions too. I’ve made several very good friends there."
      />
      <SuawParagraph
        text="It’s great to be in a group that doesn’t judge or critique your writing. I majored in Creative Writing and during my time at University, I received a lot of comments on my work that I found unhelpful. Shut Up &amp; Write! was the best fit for me because I didn’t have to worry about someone else judging my writing, which made it easier for me to write. There, I found a group of people from all walks of life just trying to finish their projects or simply exercising the writing muscle. Whenever we’d chat after our sessions, inevitably sharing the details of our manuscripts, all comments were positive. We were all moving toward the same goals."
      />
      <SuawParagraph
        text="One Wednesday night, at the atrium, I met a young couple that had just moved to the city from Europe. Since Shut Up &amp; Write! is in over 300 cities across the world, they’d already attended an event back in their hometown and immediately gravitated to the familiar group. (I did the same in my pre-Covid travel to Europe. It’s a great comfort to find events all over.) We chatted about our projects. They asked what mine was about. “It’s about making a living as a freelancer,” I said. “What’s the title?” “Well,” I said, “I have about two pages of potential titles noted. But you know what, I like the sound of ‘IT’S A LIVING.’” Simple, unassuming, and to the point. This lovely couple helped me finally settle on a title. Later that night, I started conceptualizing the cover design. I was almost done at that point, with over 70,000 words written."
      />
      <SuawParagraph
        text="I should add that Shut Up &amp; Write! alone can’t help you finish your book. You have to put in a lot of hours, sometimes over many years. I took the last three months off from my commissioned work as an artist to edit the book full-time. But meeting up with writers at least two times per week was a great help. I met award-winning writers with many books under their belts, alongside poets, copy editors, and hobbyists. It was a perfect amalgamation of hard-working creative minds each week. There were many regulars and also many new people too, so I always got a good mix of familiar faces and fresh perspectives."
      />
      <SuawParagraph
        text="As of this writing, the Coronavirus pandemic and social distancing is in full-effect in NYC. Although I’m definitely looking forward to seeing my writer friends in person again, I’m happy to report that the group is still very active and productive online. Several of us chat with our Organizer, Ed, on Discord and write even more than before. My peers depend on this group. Members often exchange editing services and at least two close friends are near to finishing their novels. Sundays are still two one hour sessions, back-to-back, and it seems like we meet online every other day. Ed checks attendance, about an hour ahead of time, and then he lets people know that “we’ll be starting in about five minutes.” At the end of the hour he calls “time.”"
      />
      <SuawParagraph
        text="Which reminds me, it’s 7pm right now. I see a notice on my phone! I’m excited to outline my next book, thanks to the great feeling after finishing my first."
      />
      <SuawParagraph text="Happy writing everyone! Now, shut up and write!" />
    </ArticleFrame>
  </SuawMainContent>
</template>
